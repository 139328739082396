document.addEventListener('DOMContentLoaded', function() {
	// Select the countdown and its component elements
	const countdownElement = document.querySelector('.countdown');
	if(!countdownElement) {
		return;
	}
	const daysElement = countdownElement.querySelector('.days');
	const daysLabel = countdownElement.querySelector('.days-label');
	const hoursElement = countdownElement.querySelector('.hours');
	const hoursLabel = countdownElement.querySelector('.hours-label');
	const minutesElement = countdownElement.querySelector('.minutes');
	const minutesLabel = countdownElement.querySelector('.minutes-label');

	// Get the target timestamp from the data attribute and convert to milliseconds
	const targetTimestamp = parseInt(countdownElement.getAttribute('data-timestamp')) * 1000;

	function updateCountdown() {
		const now = Date.now();
		const timeLeft = targetTimestamp - now;

		if (timeLeft < 0) {
			countdownElement.textContent = 'Countdown ended';
			return;
		}

		const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
		const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

		daysElement.textContent = days;
		daysLabel.textContent = days === 1 ? 'day' : 'days';
		hoursElement.textContent = hours;
		hoursLabel.textContent = hours === 1 ? 'hour' : 'hours';
		minutesElement.textContent = minutes;
		minutesLabel.textContent = minutes === 1 ? 'minute' : 'minutes';
	}

	// Initial call to display the countdown immediately
	updateCountdown();
	// Update the countdown every second
	setInterval(updateCountdown, 1000);
});
