import gsap from "gsap";

document.addEventListener('DOMContentLoaded', function () {
    const emailInput = document.querySelector(".stay-in-touch .form-container input[type=email]");
    const acceptanceInput = document.querySelector(".stay-in-touch .form-container label.terms-input");

    if (emailInput) {
        emailInput.addEventListener('input', () => {
            if (emailInput.value) {
                acceptanceInput.classList.add('visible');
            } else {
                acceptanceInput.classList.remove('visible');
            }
        })
    }


    const form = document.querySelector(".stay-in-touch #mc-embedded-subscribe-form");

    const checkbox = document.querySelector('.stay-in-touch #terms');
    if (checkbox) {
        checkbox.addEventListener('change', () => {
            if (checkbox.checked && emailInput.checkValidity()) {
                form.classList.remove("disabled");
            } else {
                form.classList.add('disabled');
            }
        })

        emailInput.addEventListener('change', () => {
            if (checkbox.checked && emailInput.checkValidity()) {
                form.classList.remove("disabled");
            } else {
                form.classList.add('disabled');
                form.classList.add('disabled');
            }
        })
    }


    if (form) {
        function logReset(event) {
            formContainer.style.display = 'none';
            formSuccessMessage.style.display = 'block';

            // if (document.querySelector('footer')) {
            // 	setTimeout(() => {
            // 		formSuccessMessage.style.opacity = '0';
            // 	}, 5000)
            // }
        }

        const formSuccessMessage = document.getElementById("form-success-message");
        form.addEventListener("reset", logReset);

        const formContainer = document.querySelector(".stay-in-touch .form-container")
        formSuccessMessage.style.height = `${formContainer.offsetHeight}px`;
    }

    //--------------------------------------------------------------
    // Contact Page

    const contactPageForms = document.querySelectorAll(".contact-form-container form");
    if (contactPageForms.length > 0) {
        contactPageForms.forEach(contactPageForm => {
            const checkbox = contactPageForm.querySelector("input[type=checkbox].acceptance");
            const submitButton = contactPageForm.querySelector("button[type=submit]");
            checkbox.addEventListener("change", () => {
                submitButton.disabled = !checkbox.checked;
            })


            const form = document.querySelector('.wpcf7');
            const dropdownLabels = document.querySelectorAll(".dropdown");

            if (dropdownLabels.length > 0) {
                dropdownLabels.forEach(label => {
                    const dropdownList = label.querySelector(".dropdown-options");
                    const dropdownItems = label.querySelectorAll(".wpcf7-list-item");

                    label.addEventListener("click", (e) => {
                        toggleDropdownMenu(dropdownList);
                        changeInputText(dropdownItems, label);
                    });
                })
            }

// Open and close dropdown menus
            function toggleDropdownMenu(list) {
                closeAllDropdowns();

                if (list.clientHeight === 0) {
                    gsap.to(list, {
                        height: "auto",
                        autoAlpha: 1,
                        duration: 0.01,
                    })
                    list.parentNode.classList.add("open");
                }
            }
        });

        function closeAllDropdowns() {
            const dropdownLists = document.querySelectorAll(".dropdown-options");
            dropdownLists.forEach(list => {
                list.parentNode.classList.remove("open");
                gsap.to(list, {
                    height: 0,
                    autoAlpha: 0,
                    duration: 0.01,
                })
            })
        }

// Change label text to selection
        function changeInputText(dropDownItems, label) {
            const dropdownLabelText = label.querySelector("span");
            const dropdownInvalidTip = label.querySelector(".wpcf7-not-valid-tip");
            const textareaPlaceholder = document.querySelector(".contact-form-container textarea[name='message']");

            dropDownItems.forEach((item) => {
                item.addEventListener("click", (e) => {
                    if (e.target.classList.contains("wpcf7-list-item-label")) {
                        dropdownLabelText.textContent = e.target.textContent;
                        dropdownLabelText.classList.add("selected");
                        if (dropdownInvalidTip) {
                            dropdownInvalidTip.style.display = 'none';
                        }
                        switch (e.target.textContent) {
                            case 'Register to buy':
                                textareaPlaceholder.placeholder = 'Hello, I would like to register...';
                                break;
                            case 'Register to sell':
                                textareaPlaceholder.placeholder = 'Hello, I would like to sell my property...';
                                break;
                            case 'Register to rent':
                                textareaPlaceholder.placeholder = 'Hello, I would like to register to rent...';
                                break;
                            case 'Letting and managing':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in letting and management...';
                                break;
                            case 'Sport and Entertainment':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in sport and entertainment...';
                                break;
                            case 'Investment Opportunities':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in investment opportunities...';
                                break;
                            case 'Equestrian services':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in equestrian services...';
                                break;
                            case 'Lifestyle':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in the Private Office : Lifestyle...';
                                break;
                            case 'Protects':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in the Private Office : Protects...';
                                break;
                            case 'Request Access':
                                textareaPlaceholder.placeholder = 'Hello, I would like to request access...';
                                break;
                            case 'International Payments':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in international payments...';
                                break;
                            case 'Insurance and Debt':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in insurance and debt...';
                                break;
                            case 'Other':
                                textareaPlaceholder.placeholder = 'Hello, I am interested in...';
                                break;
                            default:
                                textareaPlaceholder.placeholder = 'Hello, I am interested in...';
                                break;
                        }

                    }
                })
            })
        }


// Reset dropdown menu on submit

        if (form) {
            form.addEventListener("wpcf7mailsent", () => {
                const serviceLabel = document.querySelector(".dropdown .service-label span");
                serviceLabel.textContent = "Service*";

                serviceLabel.classList.remove("selected");

            })
        }


        // Function to move the .wpcf7-not-valid-tip element up to its parent
        function moveInvalidTip() {
            // Select all .dropdown-options elements
            const dropdownOptionsElements = document.querySelectorAll('.dropdown');

            dropdownOptionsElements.forEach(dropdown => {
                // Check if a .wpcf7-not-valid-tip exists inside the dropdown
                const invalidTip = dropdown.querySelector('.wpcf7-not-valid-tip');
                if (invalidTip && invalidTip.parentElement !== dropdown) {
                    // Temporarily disconnect the observer to prevent infinite loop
                    observer.disconnect();

                    // Move the .wpcf7-not-valid-tip element to the dropdown itself
                    dropdown.appendChild(invalidTip);

                    // Reconnect the observer after making changes
                    observer.observe(document.body, {childList: true, subtree: true});
                }
            });
        }

        // Create a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    moveInvalidTip();
                }
            });
        });

        // Start observing the document body for added nodes
        observer.observe(document.body, {childList: true, subtree: true});

        // Also call moveInvalidTip in case the element is added immediately
        moveInvalidTip();

    }

    //--------------------------------------------------------------
    // Contact Form Popup

    const contactPopup = document.querySelector(".contact-popup");

    if (contactPopup) {
        const serviceLabel = document.querySelector(".dropdown .service-label span");
        const dropdownOptions = contactPopup.querySelectorAll(".dropdown-options .wpcf7-list-item");

        const pathname = window.location.pathname;
        const pathSegments = pathname.split('/');
        const subdirectories = pathSegments.filter(segment => segment !== '');

        dropdownOptions.forEach(option => {
            const input = option.querySelector("input");
            const label = option.querySelector(".wpcf7-list-item-label");
            const inputValue = input.value;

            if (subdirectories[0] === 'properties' || subdirectories[0] === 'listing') {
                if (inputValue.toLowerCase() === 'register to buy') {
                    serviceLabel.textContent = label.textContent;
                    serviceLabel.classList.add("selected");
                    input.checked = true;
                }
            } else if (subdirectories[0] === 'lifestyle') {
                if (inputValue.toLowerCase() === 'lifestyle') {
                    serviceLabel.textContent = label.textContent;
                    serviceLabel.classList.add("selected");
                    input.checked = true;
                }
            } else if (subdirectories[0] === 'protects') {
                if (inputValue.toLowerCase() === 'protects') {
                    serviceLabel.textContent = label.textContent;
                    serviceLabel.classList.add("selected");
                    input.checked = true;
                }
            }
        });

        const closeBtn = contactPopup.querySelector(".close-btn");
        const openContactPopupButtons = document.querySelectorAll(".open-contact-popup");

        const toggleContactPopup = () => {
            if (contactPopup.classList.contains("open")) {
                contactPopup.classList.remove("open");
            } else {
                contactPopup.classList.add("open");
            }
        };

        const closeContactPopup = () => {
            contactPopup.classList.remove("open");
        };

        openContactPopupButtons.forEach(button => {
            button.addEventListener("click", (event) => {
                event.stopPropagation(); // Prevent the click event from bubbling up
                toggleContactPopup();

                const service = button.dataset.service;
                if (service) {
                    dropdownOptions.forEach(option => {
                        const input = option.querySelector("input");
                        const label = option.querySelector(".wpcf7-list-item-label");
                        const inputValue = input.value;

                        if (inputValue === service) {
                            serviceLabel.textContent = label.textContent;
                            serviceLabel.classList.add("selected");
                            input.checked = true;
                        }
                    });
                }
            });
        });

        closeBtn.addEventListener("click", (event) => {
            event.stopPropagation(); // Prevent the click event from bubbling up
            toggleContactPopup();
        });

        // Close popup when clicking outside of it
        document.addEventListener("click", (event) => {
            if (contactPopup.classList.contains("open") && !contactPopup.contains(event.target) && !Array.from(openContactPopupButtons).includes(event.target)) {
                closeContactPopup();
            }
        });

        // Close popup when pressing the Esc key
        document.addEventListener("keydown", (event) => {
            if (event.key === "Escape" && contactPopup.classList.contains("open")) {
                closeContactPopup();
            }
        });
    }

});