const header = document.querySelector("#site-header");

if (header) {
	const menuToggle = header.querySelector(".menu-toggle");
	menuToggle.addEventListener("click", toggleMenu);
}


function toggleMenu() {
	header.classList.toggle("mobile-menu-active");
}