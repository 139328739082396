document.addEventListener("DOMContentLoaded", () => {

    const mapPopup = document.querySelector("#listing-map-popup");
    const galleryPopup = document.querySelector("#listing-gallery-popup");
    const floorPlanPopup = document.querySelector("#listing-floor-plan-popup");
    const videoPopup = document.querySelector("#listing-video-popup");
    const infoPopup = document.querySelector("#listing-info-popup");
    const agentContactPopup = document.querySelector("#listing-agent-contact-popup");

    const popups = [mapPopup, galleryPopup, floorPlanPopup, videoPopup, infoPopup, agentContactPopup];

    popups.forEach(popup => {
        if (popup) {
            initPopup(popup);
        }
    })

    function initPopup(popup) {
        const closeButton = popup.querySelector(".close-btn");
        const dataAttr = popup.id.replace(/-popup$/, '')
        const popUpToggleButtons = document.querySelectorAll(`[data-open="${dataAttr}"]`)

        closeButton.addEventListener("click", () => {
            togglePopup(popup);

        })

        popUpToggleButtons.forEach(button => {
            button.addEventListener("click", (event) => {
                event.stopPropagation(); // Prevent the click event from bubbling up
                togglePopup(popup);
            })
        })

        // Close popup when clicking outside of it
        document.addEventListener("click", (event) => {
            if (popup.classList.contains("open") && !popup.contains(event.target) && !Array.from(popUpToggleButtons).includes(event.target)) {
                closePopup(popup);
            }
        });

        // Close popup when pressing the Esc key
        document.addEventListener("keydown", (event) => {
            if (event.key === "Escape" && popup.classList.contains("open")) {
                closePopup(popup);
            }
        });


        if (popup === agentContactPopup) {
            const propertyAddress = document.querySelector(".listing .listing-title h1").textContent.trim();
            const messageInput = agentContactPopup.querySelector(".message-and-submit textarea");
            const agentEmailAddress = agentContactPopup.querySelector(".agent-email-address").textContent.trim();
            const genericEmailAddress = document.querySelector(".contact-email");

            const hiddenPropertyInput = agentContactPopup.querySelector(".property-field");
            const hiddenMailToInput = agentContactPopup.querySelector(".mailto-field");

            hiddenPropertyInput.value = propertyAddress;
            hiddenMailToInput.value = agentEmailAddress ? agentEmailAddress : genericEmailAddress;

            messageInput.placeholder = `I'm interested in ${propertyAddress}...`

        }
    }

    const togglePopup = (popup) => {
        let vimeoPlayer = undefined;

        if (popup === videoPopup) {
            const iframe = popup.querySelector('iframe');
            if (iframe) {
                vimeoPlayer = new Vimeo.Player(iframe);
            }
        }

        if (popup.classList.contains("open")) {
            popup.classList.remove("open");
            document.body.classList.remove('popup-open');

            if (vimeoPlayer) {
                vimeoPlayer.pause().catch((error) => {
                    console.error('Error pausing video:', error);
                });
            }
        } else {
            popup.classList.add("open");
            document.body.classList.add('popup-open');

            if (vimeoPlayer) {
                vimeoPlayer.play().catch((error) => {
                    console.error('Error playing video:', error);
                });
            }
        }
    };

    const closePopup = (popup) => {
        popup.classList.remove("open");
        document.body.classList.remove('popup-open');

        if (popup === videoPopup) {
            const iframeElement = popup.querySelector('iframe');
            if (iframeElement && iframeElement.src.includes('vimeo.com')) {
                const vimeoPlayer = new Vimeo.Player(iframeElement);
                vimeoPlayer.pause().catch((error) => {
                    console.error('Error pausing Vimeo video:', error);
                });
            }
        }
    };


})