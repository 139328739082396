import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {

	const header = document.querySelector("#site-header");

	// ----------------------------------------------------------------------------
	// Pin + animate footer

	const footer = document.querySelector("footer#site-footer");

	if (footer) {
		const footerContent = footer.querySelector(".content-container");

		ScrollTrigger.create({
			trigger: footer,
			pin: true,
			pinSpacing: false,
			start: () => "bottom bottom",
			endTrigger: "html",
			end: () => "bottom bottom",
			invalidateOnRefresh: true,
			onEnter: () => {
				const pinSpacer = footer.parentElement;
				pinSpacer.classList.add("footer-pin-spacer");
			},
		});

		const stayInTouch = document.querySelector(".stay-in-touch");
		const main = document.querySelector("main");

		const triggerElement = stayInTouch ? stayInTouch : main;

		gsap.from(footerContent, {
			y: 75,
			opacity: 0,
			ease: 'none',
			scrollTrigger: {
				trigger: triggerElement,
				start: () => 'bottom bottom',
				end: () => `bottom bottom-=${footer.offsetHeight}`,
				scrub: true,
				invalidateOnRefresh: true,
			}
		})
	}

	// ----------------------------------------------------------------------------
	// Dual column pinned hero

	const pinnedHero = document.querySelector(".pinned-hero");

	if (pinnedHero) {
		const leftCol = pinnedHero.querySelector(".left-col");
		const rightCol = pinnedHero.querySelector(".right-col");

		let mm = gsap.matchMedia();

		mm.add("(min-width: 1260px)", () => {
			// Create the ScrollTrigger
			let scrollTrigger = ScrollTrigger.create({
				trigger: leftCol,
				pin: rightCol,
				pinSpacing: false,
				start: () => `top top+=${header.offsetHeight}`,
				end: "bottom bottom",
			});

			// Adjust padding top
			const adjustPadding = () => {
				const titleAndCopy = leftCol.querySelector(".title-and-copy");
				leftCol.style.paddingTop = (((window.innerHeight - titleAndCopy.offsetHeight) / 2) - header.offsetHeight) + 'px';
			};

			// Initial padding adjustment
			adjustPadding();

			// Event listener for window resize
			const handleResize = () => {
				if (window.innerWidth >= 1260) {
					adjustPadding();
				} else {
					// Reset padding to the CSS default
					leftCol.style.paddingTop = '';
				}
			};

			window.addEventListener('resize', handleResize);

		});
	}

	// ----------------------------------------------------------------------------
	// Header

	if (header && header.classList.contains("transparent")) {
		const hero = document.querySelector(".hero");

		if (hero) {
			ScrollTrigger.create({
				trigger: ".hero .title-container",
				start: () => `top top+=${header.offsetHeight}`,
				onEnter: () => header.classList.add("opaque"),
				onLeaveBack: () => header.classList.remove("opaque"),
			});
		} else if (document.querySelector(".page-404")) {
			ScrollTrigger.create({
				trigger: ".page-404",
				start: () => `bottom top+=${header.offsetHeight}`,
				onEnter: () => header.classList.add("opaque"),
				onLeaveBack: () => header.classList.remove("opaque"),
			});
		}

	}


	// ----------------------------------------------------------------------------
	// Hero scroll animation

	const hero = document.querySelector(".hero.full-bleed");

	if (hero) {

		const overlay = hero.querySelector(".overlay");

		gsap.to(overlay, {
			opacity: 1,
			scrollTrigger: {
				trigger: hero,
				start: "top top",
				end: "bottom top",
				pin: ".hero .background-container",
				pinSpacing: false,
				scrub: 0.7,
			}
		})
	}

	// ----------------------------------------------------------------------------
	// Short hero pin

	const shortHero = document.querySelector(".hero.short");

	if (shortHero) {

		ScrollTrigger.create({
			trigger: shortHero,
			pin: true,
			pinSpacing: false
		})
	}


	// ----------------------------------------------------------------------------
	// Two Pinned Columns Section (Protects and Lifestyle pages)

	const twoPinnedColumns = document.querySelectorAll(".two-pinned-columns");

	if (twoPinnedColumns.length > 0) {
		twoPinnedColumns.forEach(twoPinnedColumns => {
			const scrollCol = twoPinnedColumns.querySelector(".scrolling-column");
			const pinnedCol = twoPinnedColumns.querySelector(".pinned-column");

			let mm = gsap.matchMedia();

			mm.add("(min-width: 1260px)", () => {
				// Create the ScrollTrigger
				let scrollTrigger = ScrollTrigger.create({
					trigger: scrollCol,
					pin: pinnedCol,
					pinSpacing: false,
					start: () => `top top+=${header.offsetHeight}`,
					end: "bottom bottom",
				});


			});
		})

	}


	// ----------------------------------------------------------------------------
	// Two Pinned Columns Section (Contact page)

	const contactSection = document.querySelector(".contact");

	if (contactSection) {
		const scrollCol = contactSection.querySelector(".scrolling-col");
		const pinnedCol = contactSection.querySelector(".pinned-col");

		let mm = gsap.matchMedia();

		mm.add("(min-width: 1260px)", () => {
			// Create the ScrollTrigger
			let scrollTrigger = ScrollTrigger.create({
				trigger: scrollCol,
				pin: pinnedCol,
				pinSpacing: false,
				start: () => `top top+=${header.offsetHeight}`,
				end: "bottom bottom",
				invalidateOnRefresh: true,
			});


		});

	}


	// ----------------------------------------------------------------------------
	// Two Pinned Columns Section (Listings pages)

	const listingPinnedSection = document.querySelector(".listing");

	if (listingPinnedSection) {
		const scrollCol = listingPinnedSection.querySelector(".scroll-col");
		const pinnedCol = listingPinnedSection.querySelector(".pinned-col");
		const container = pinnedCol.querySelector(".container");

		const distance = pinnedCol.clientHeight - container.scrollHeight;

		let mm = gsap.matchMedia();

		mm.add("(min-width: 1260px)", () => {
			// Create the ScrollTrigger
			let scrollTrigger = ScrollTrigger.create({
				trigger: pinnedCol,
				endTrigger: scrollCol,
				pin: pinnedCol,
				// pinSpacing: false,
				start: () => `top top+=${header.offsetHeight}`,
				end: () => `bottom bottom-=${window.innerHeight - container.offsetHeight - 120 - 30}`,
			});
		});
	}

	// ----------------------------------------------------------------------------
	// Button Row (Listings pages)

	const buttonRow = document.querySelector(".listing .button-row");
	if (buttonRow) {

		let mm = gsap.matchMedia();

		mm.add("(min-width: 1260px)", () => {
			ScrollTrigger.create({
				trigger: buttonRow,
				start: () => `top top+=${header.offsetHeight - 1}`,
				endTrigger: ".listing-info",
				end: `bottom top+=${header.offsetHeight + buttonRow.offsetHeight - 2}`,
				pin: true,
				pinSpacing: false,
			})
		});
	}


	// ----------------------------------------------------------------------------
	// Refresh Scrolltrigger when listings are loaded on properties page
	document.addEventListener('listingsUpdated', function () {
		if (typeof ScrollTrigger !== 'undefined') {
			ScrollTrigger.refresh();
		}
	});
});
