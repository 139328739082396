import gsap from "gsap";

document.addEventListener("DOMContentLoaded", () => {
	const servicesGrid = document.querySelector(".services");

	if (servicesGrid) {
		const services = servicesGrid.querySelectorAll(".service:not(.last-cell)");

		const toggleService = (event) => {
			const closestService = event.target.closest(".service");
			const isOpen = closestService.classList.contains("open");

			// Close all services
			services.forEach(service => {
				const dropDownContent = service.querySelector(".service-dropdown-content");
				gsap.to(dropDownContent, {
					height: 0,
					ease: "power2.out",
					duration: 0.5
				});
				service.classList.remove("open");
			});

			// If the clicked service was not already open, open it
			if (!isOpen) {
				const dropDownContent = closestService.querySelector(".service-dropdown-content");
				closestService.classList.add("open");

				// Initial distance from top before animation
				const initialRect = closestService.getBoundingClientRect();
				const initialDistanceFromTop = initialRect.top + window.scrollY;

				gsap.to(dropDownContent, {
					height: 'auto',
					ease: "power2.out",
					duration: 0.5,
					onUpdate: () => {
						// Get the current distance from top during animation
						const header = document.querySelector("header");
						const currentRect = closestService.getBoundingClientRect();
						const currentDistanceFromTop = (currentRect.top + window.scrollY) - (header.offsetHeight * 2);

						// Calculate the change in height
						const heightChange = currentDistanceFromTop - initialDistanceFromTop;

						// Adjust the scroll position accordingly
						window.scrollTo({
							top: initialDistanceFromTop + heightChange,
							// behavior: 'auto' // 'auto' behavior to avoid additional smooth scrolling conflicts
						});
					}
				});
			}
		};

		let isBelowThreshold = window.matchMedia("(max-width: 720px)").matches;
		let listenersAdded = false;

		const manageServicesEventListeners = () => {
			const newIsBelowThreshold = window.matchMedia("(max-width: 720px)").matches;

			if (newIsBelowThreshold && !listenersAdded) {
				services.forEach(service => {
					const serviceTitle = service.querySelector(".service-title-container");
					serviceTitle.addEventListener("click", toggleService);
				});
				listenersAdded = true;
			} else if (!newIsBelowThreshold && listenersAdded) {
				services.forEach(service => {
					const serviceTitle = service.querySelector(".service-title-container");
					serviceTitle.removeEventListener("click", toggleService);
				});
				listenersAdded = false;
			}

			isBelowThreshold = newIsBelowThreshold;
		}

		manageServicesEventListeners();

		window.addEventListener("resize", manageServicesEventListeners);

	}

	// ------------------------------------------------------------------

	// Show / hide people on mobile

	const peopleGrid = document.querySelector(".people-grid");

	if (peopleGrid) {
		const roleTitles = peopleGrid.querySelectorAll(".role-category .category-title-container");
		roleTitles.forEach(role => {
			role.addEventListener("click", (event) => {
				const closestContent = event.target.nextElementSibling;
				const roleContainer = event.target.parentElement;

				roleContainer.classList.toggle("open");

				if (roleContainer.classList.contains("open")) {
					gsap.to(closestContent, {
						height: 'auto',
						ease: "power2.inOut",
						duration: 0.5
					});
				} else {
					gsap.to(closestContent, {
						height: 0,
						ease: "power2.inOut",
						duration: 0.5
					});
				}
			});
		});
	}
});
