document.addEventListener('DOMContentLoaded', function () {
	const filterForm = document.getElementById('filter-form')
	const listingsContainer = document.getElementById('listings-container')

	if (filterForm) {
		// Remove any existing event listeners to prevent duplicates
		function removeEventListeners (selector, event, handler) {
			document.querySelectorAll(selector).forEach(element => {
				const clone = element.cloneNode(true)
				element.replaceWith(clone)
			})
			document.querySelectorAll(selector).forEach(element => {
				element.addEventListener(event, handler)
			})
		}

		// Toggle main dropdowns
		const toggleMainDropdown = function () {
			const currentDropdownMenu = document.getElementById(`${this.getAttribute('data-dropdown')}-dropdown`)

			// Close other main dropdowns
			document.querySelectorAll('.filter-dropdown .dropdown-menu').forEach(menu => {
				if (menu !== currentDropdownMenu) {
					menu.classList.remove('show')
				}
			})

			// Toggle current main dropdown
			currentDropdownMenu.classList.toggle('show')
		}
		removeEventListeners('.dropdown-toggle', 'click', toggleMainDropdown)

		// Toggle nested dropdowns
		const toggleNestedDropdown = function () {
			const currentNestedDropdownMenu = document.getElementById(`${this.getAttribute('data-nested-dropdown')}-dropdown`)

			// Close other nested dropdowns
			document.querySelectorAll('.nested-dropdown .nested-dropdown-menu').forEach(menu => {
				if (menu !== currentNestedDropdownMenu) {
					menu.parentNode.classList.remove('show')
				}
			})

			// Toggle current nested dropdown
			this.parentNode.classList.toggle('show')
		}
		removeEventListeners('.nested-dropdown-toggle', 'click', toggleNestedDropdown)

		// Close dropdowns if clicked outside
		document.addEventListener('click', function (event) {
			const isDropdownButton = event.target.matches('.dropdown-toggle') || event.target.matches('.nested-dropdown-toggle')
			const isInsideDropdown = event.target.closest('.filter-dropdown') || event.target.closest('.nested-dropdown')

			if (!isDropdownButton && !isInsideDropdown) {
				document.querySelectorAll('.filter-dropdown .dropdown-menu, .nested-dropdown .nested-dropdown-menu').forEach(menu => {
					menu.classList.remove('show')
					menu.parentNode.classList.remove('show')
				})
			}
		})

		// Handle deselection of radio buttons and close dropdown
		const handleRadioClick = function () {
			const dropdownMenu = this.closest('.dropdown-menu')

			if (this.checked && this.dataset.waschecked === 'true') {
				this.checked = false
				this.dataset.waschecked = 'false'
				filterForm.dispatchEvent(new Event('change')) // Trigger the form change to update the listings
			} else {
				document.querySelectorAll(`input[name=${this.name}]`).forEach(otherRadio => {
					otherRadio.dataset.waschecked = 'false'
				})
				this.dataset.waschecked = 'true'
				filterForm.dispatchEvent(new Event('change')) // Trigger the form change to update the listings
			}
			// Close the dropdown after selection
			dropdownMenu.classList.remove('show')
		}
		removeEventListeners('input[type=radio]', 'click', handleRadioClick)

		// Load location options dynamically
		const locationDropdown = document.getElementById('location-dropdown')
		fetch(ajaxurl, {
			method: 'POST',
			body: new URLSearchParams({ action: 'get_locations' }),
		})
			.then(response => response.json())
			.then(data => {
				if (!data.success) {
					return
				}

				const locations = Object.values(data.data.locations) // Convert object to array

				// Clear existing location options
				locationDropdown.innerHTML = ''

				locations.forEach(location => {
					const label = document.createElement('label')
					label.innerHTML = `<input type="checkbox" name="location[]" value="${location}"> ${location}`
					locationDropdown.appendChild(label)
				})

				// After updating the DOM, refresh the button event listeners
				if (window.refreshButtonEventListeners) {
					window.refreshButtonEventListeners()
				}
			})
			.catch(error => console.error('Error:', error))

		// Handle form change
		filterForm.addEventListener('change', function () {
			const formData = new FormData(filterForm)
			formData.append('action', 'filter_listings') // Add the action parameter

			fetch(ajaxurl, {
				method: 'POST',
				body: new URLSearchParams(formData),
			})
				.then(response => response.text())
				.then(data => {
					listingsContainer.innerHTML = data
					// Dispatch custom event after updating the listings
					document.dispatchEvent(new Event('listingsUpdated'))
					// After updating the DOM, refresh the button event listeners
					if (window.refreshButtonEventListeners) {
						window.refreshButtonEventListeners()
					}
				})
				.catch(error => console.error('Error:', error))
		})

		// Update button text and close nested dropdown when an option is selected
		const handleNestedDropdownChange = function () {
			const button = this.closest('.nested-dropdown').querySelector('.nested-dropdown-toggle')

			if (this.name === 'price_to' && this.value === '') {
				button.textContent = 'No Maximum'
			} else if (this.name === 'price_to' || this.name === 'price_from') {
				button.textContent = `£${parseInt(this.value).toLocaleString()}`
			}
			this.closest('.nested-dropdown').classList.remove('show')
			filterForm.dispatchEvent(new Event('change')) // Trigger the form change to update the listings
		}
		removeEventListeners('.nested-dropdown-menu input', 'change', handleNestedDropdownChange)

		// Trigger change event on page load to display listings initially
		filterForm.dispatchEvent(new Event('change'))
	}
})
