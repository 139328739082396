import gsap from 'gsap';

//-----------------------------------------------
// Lock scrolling
//-----------------------------------------------

let currentScrollPosition = window.pageYOffset;

function preventScrolling() {
	window.scrollTo(0, currentScrollPosition);
}

function lockWindowScroll() {
	currentScrollPosition = window.pageYOffset;
	window.addEventListener('scroll', preventScrolling);
}

function unlockWindowScroll() {
	window.removeEventListener('scroll', preventScrolling);
}

//-----------------------------------------------
// Trap Focus
//-----------------------------------------------

// https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
function trapFocus(element) {
	element.classList.add('focus-trap-active');

	const focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
	const firstFocusableEl = focusableEls[0];  
	const lastFocusableEl = focusableEls[focusableEls.length - 1];
	const KEYCODE_TAB = 9;

	element.addEventListener('keydown', function(e) {
		const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

		if (!isTabPressed || !element.classList.contains('focus-trap-active')) return

		if (e.shiftKey) /* shift + tab */ {
			if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} else /* tab */ {
			if (document.activeElement === lastFocusableEl) {
				firstFocusableEl.focus();
				e.preventDefault();
			}
		}
	});
}

function removeTrapFocus(element) {
	element.classList.remove('focus-trap-active');
}

//-----------------------------------------------
// Clipboard Copying
//-----------------------------------------------

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
function copyToClipboard(text) {
	if (window.clipboardData && window.clipboardData.setData) {
		// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
		return clipboardData.setData("Text", text);
	} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
		var textarea = document.createElement("textarea");
		textarea.textContent = text;
		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			return document.execCommand("copy");  // Security exception may be thrown by some browsers.
		}
		catch (ex) {
			console.warn("Copy to clipboard failed.", ex);
			return false;
		}
		finally {
			document.body.removeChild(textarea);
		}
	}
}

//-----------------------------------------------
// Login
//-----------------------------------------------

function initLoginForm() {
	const loginForm = document.querySelector('.login');
	if (!loginForm) return;
	const formWrap = loginForm.querySelector('.login__form-wrap');
	const passwordField = loginForm.querySelector('.login__password-field');
	const errorContainer = loginForm.querySelector('.login__form-error');

	function loginFormSubmit(e) {
		const el = e.target;
		e.preventDefault();
		const form = el;
		const password = passwordField.value;
		let error = false;
		errorContainer.innerHTML = '';
		
		if (password.length < 1) {
			error = 'Please enter a password';
		}

		if (error) {
			errorContainer.classList.add('active');
			errorContainer.append(document.createTextNode(error));
			passwordField.focus();
			return;
		}

		checkAccess(password);
	}

	function passwordInput(e) {
		errorContainer.classList.remove('active');
	}

	function checkAccess(password) {
		const data = {
			action: 'ajax_login',
			access: password,
		}

		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.access === true && result.token) {
					window.location.href = result.redirect;
				} else {
					errorContainer.classList.add('active');
					errorContainer.append(document.createTextNode(result.error));
					passwordField.focus();
				}
			}
		});
	}

	loginForm.addEventListener('submit', loginFormSubmit, false);

	passwordField.addEventListener('keypress', passwordInput, false);
}

initLoginForm();

//-----------------------------------------------
// Accounts Sorting
//-----------------------------------------------

function accountsSorting() {
	const sortControl = document.querySelector('.accounts__sort-value');
	if (!sortControl) return;
	const container = sortControl.parentNode;
	const controlLabel = sortControl.querySelector('span');

	sortControl.addEventListener('click', e => {
		const isOpen = container.classList.contains('active');
		if (isOpen) {
			container.classList.remove('active');
		} else {
			container.classList.add('active');
		}
	});
	const optionControls = container.querySelectorAll('.accounts__sort-options-option');
	optionControls.forEach(control => {
		control.addEventListener('click', e => {
			const value = control.dataset.value;
			if (sortControl.dataset.value !== value) {
				sortControl.dataset.value = value;
				controlLabel.textContent = value;

				const rows = document.querySelectorAll('.accounts-table__table-body-row');
				const tbody = document.querySelector('.accounts-table__table-body');
				if (value === 'Alphabetical') {
					tbody.innerHTML = '';
					Array.from(rows).sort(function(a, b) {
						if (a.attributes[2].nodeValue < b.attributes[2].nodeValue) return -1;
						if (a.attributes[2].nodeValue > b.attributes[2].nodeValue) return 1;
					}).forEach(el => {
						tbody.appendChild(el);
					});
				} else if (value === 'Date') {
					tbody.innerHTML = '';
					Array.from(rows).sort(function(a, b) {
						a = new Date(a.attributes[3].nodeValue).valueOf();
						b = new Date(b.attributes[3].nodeValue).valueOf();
						return a - b;
					}).forEach(el => {
						tbody.appendChild(el);
					});
				}
			}
			container.classList.remove('active');
		});
	});
}

accountsSorting();

//-----------------------------------------------
// Accounts
//-----------------------------------------------

var createAccountControls = document.querySelectorAll('.accounts__add-button');
var editAccountControls = document.querySelectorAll('.accounts-table__table-body-name-edit');

if (createAccountControls.length > 0) {
	var createAccountModal = document.querySelector('.account-create');
	var createAccountModalInner = createAccountModal.querySelector('.account-create__inner');
	var createAccountForm = createAccountModal.querySelector('#account-create');

	var editAccountModal = document.querySelector('.account-edit');
	var editAccountModalInner = editAccountModal.querySelector('.account-edit__inner');
	var editAccountForm = editAccountModal.querySelector('#account-edit');

	var accountInputsForValidation = document.querySelectorAll('.account-create input.validate, .account-edit input.validate');
	var nameFieldId = 'client_name';
	var accessFieldId = 'access_code';
	var durationFieldId = 'duration';
	var welcomeMessageFieldId = 'welcome_message';
	var propertyFieldId = 'property';
	var propertyField = document.getElementById(propertyFieldId);

	var errorValues = {
		'empty': {
			'client_name': 'Client Name is required',
			'e_client_name': 'Client Name is required',
			'access_code': 'Access Code is required',
			'e_access_code': 'Access Code is required',
			'duration': 'Duration (days) is required',
		}
	}

	function openCreateAccountModal(e) {
		if (!e.target.classList.contains('accounts__add-button')) return;
		createAccountModal.classList.add('active');
		e.target.blur();
		setTimeout(function() {
			var randomNum = Math.floor(Math.random() * 10000000);
			document.getElementById(accessFieldId).value = randomNum;
			document.getElementById(nameFieldId).focus();
		}, 300);
	}

	function openEditAccountModal(e) {
		if (!e.target.classList.contains('accounts-table__table-body-name-edit')) return;
		editAccountModal.classList.add('active');
		e.target.blur();
		const control = e.target;
		const row = control.closest('.accounts-table__table-body-row');
		const id = row.dataset.accountId;

		var data = {
			action: 'ajax_get_account_edit_data',
			account: id,
		}

		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.error) {
					console.log(result.error);
				} else {
					editAccountModal.dataset.account = id;
					const nameField = editAccountModal.querySelector('#e_client_name');
					const accessField = editAccountModal.querySelector('#e_access_code');
					const durationField = editAccountModal.querySelector('#e_duration');
					const welcomeField = editAccountModal.querySelector('#e_welcome_message');
					const propertyField = editAccountModal.querySelector('#e_property');
					const properties = editAccountModal.querySelector('.account-edit__form-field-properties');
					const uploads = editAccountModal.querySelector('.account-edit__form-field-uploads');
					nameField.value = result.name;
					accessField.value = result.access_code;
					durationField.value = result.duration;
					welcomeField.value = result.welcome_message;
					properties.innerHTML = result.properties;
					uploads.innerHTML = result.uploads;

					setTimeout(() => {
						const propertyOptions = propertyField.options;
						for (var i = 0; i < propertyOptions.length; i++) {
							if (propertyOptions[i].value) {
								const hasProperty = properties.querySelector('.account-edit__form-field-properties-item[data-listing="' + propertyOptions[i].value + '"]');
								if (hasProperty) {
									propertyOptions[i].remove();
								}
							}
						}
					}, 10);
				}
			}
		});
	}

	function closeAccountModal(e) {
		const button = e.target;
		if (!button.classList.contains('account-create__modal-close') && !button.classList.contains('account-edit__modal-close')) return;
		let baseClass;
		let form;
		if (button.classList.contains('account-edit__modal-close')) {
			form = editAccountForm;
			baseClass = '.account-edit__';
		} else {
			form = createAccountForm;
			baseClass = '.account-create__';
		}

		const modal = button.closest('aside');
		modal.classList.remove('active');
		accountResetValidation();

		const accountPropertiesDelete = form.querySelectorAll(baseClass + 'form-field-properties-item-property-delete');
		const accountGeneralUploadsDelete = form.querySelectorAll(baseClass + 'form-field-uploads-upload-delete');

		accountPropertiesDelete.forEach(button => {
			button.click()
		});
		accountGeneralUploadsDelete.forEach(button => {
			button.click();
		});

	}

	function closeOpenAccountModal(e) {
		const activeCreateAccountModal = createAccountModal.classList.contains('active');
		const activeEditAccountModal = editAccountModal.classList.contains('active');

		if (activeCreateAccountModal || activeEditAccountModal) {
			if (e.key === 'Escape') {
				if (activeCreateAccountModal) {
					const closeButton = createAccountModal.querySelector('.modal-close');
					closeButton.click();
				}
				if (activeEditAccountModal) {
					const closeButton = editAccountModal.querySelector('.modal-close');
					closeButton.click();
				}
			} else {
				if (e.target.classList.contains('account-create')) {
					const closeButton = createAccountModal.querySelector('.modal-close');
					closeButton.click();
				}
				if (e.target.classList.contains('account-edit')) {
					const closeButton = editAccountModal.querySelector('.modal-close');
					closeButton.click();
				}
			}
		}
	}

	function submitCreateAccountForm(e) {
		e.preventDefault();
		var name = document.getElementById(nameFieldId);
		var nameField = name.parentNode;
		var nameFieldValidation = nameField.querySelector('.account-create__form-field-validation');
		var access = document.getElementById(accessFieldId);
		var accessField = access.parentNode;
		var accessFieldValidation = accessField.querySelector('.account-create__form-field-validation');
		var duration = document.getElementById(durationFieldId);
		var durationField = duration.parentNode;
		var durationFieldValidation = durationField.querySelector('.account-create__form-field-validation');
		var welcomeMessage = document.getElementById(welcomeMessageFieldId);
		var welcomeMessageField = welcomeMessage.parentNode;

		var submitButton = createAccountForm.querySelector('.account-create__form-submit');
		submitButton.blur();

		var nameIsValid = accessIsValid = false;

		if (name.value.length < 1) {
			nameField.classList.add('invalid');
			nameFieldValidation.textContent = errorValues.empty[nameFieldId];
		} else {
			nameField.classList.remove('invalid');
			nameFieldValidation.textContent = '';
			nameIsValid = true;
		}
		if (access.value.length < 1) {
			accessField.classList.add('invalid');
			accessFieldValidation.textContent = errorValues.empty[accessFieldId];
		} else {
			accessField.classList.remove('invalid');
			accessFieldValidation.textContent = '';	
			accessIsValid = true;
		}

		if (!nameIsValid || !accessIsValid) return;

		const properties = createAccountForm.querySelectorAll('.account-create__form-field-properties-item');
		let clientProperties = {};
		if (properties.length > 0) {
			let i = 0;
			properties.forEach(property => {
				const clientPropertyUploads = {};
				const propertyUploads = property.querySelectorAll('.account-create__form-field-uploads-upload');
				let j = 0;
				propertyUploads.forEach(upload => {
					clientPropertyUploads[j] = {
						"file": upload.dataset.file,
						"label": upload.dataset.label,
					}
					j++;
				});
				clientProperties[i] = {
					"listing": property.dataset.listing,
					"uploads": clientPropertyUploads,
				};
				i++;
			});
		}

		const generalUploadsField = createAccountForm.querySelector('.account-create__form-field--general-uploads');
		const generalUploads = generalUploadsField.querySelectorAll('.account-create__form-field-uploads-upload');
		const clientGeneralUploads = {};
		if (generalUploads.length > 0) {
			let i = 0;
			generalUploads.forEach(upload => {
				clientGeneralUploads[i] = {"file": upload.dataset.file, "label": upload.dataset.label};
				i++;
			});
		}

		// Make form inactive
		createAccountModal.classList.add('inactive');

		let no_duration = false;
		if (duration.value.length < 1) {
			no_duration = true;
		}

		var data = {
			action: 'ajax_create_account',
			name: name.value,
			access: access.value,
			no_duration: no_duration,
			duration: duration.value,
			message: welcomeMessage.value,
			properties: JSON.stringify(clientProperties),
			uploads: JSON.stringify(clientGeneralUploads),
		}

		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.access_error) {
					accessField.classList.add('invalid');
					access.focus();
					accessFieldValidation.textContent = result.access_error;
					createAccountModal.classList.remove('inactive');
				} else if (result.duration_error) {
					durationField.classList.add('invalid');
					duration.focus();
					durationFieldValidation.textContent = result.duration_error;
					createAccountModal.classList.remove('inactive');
				} else {
					createAccountModal.classList.remove('active');
					updateAccountTable(result);
					setTimeout(function() {
						createAccountModal.classList.remove('inactive');
						name.value = '';
						access.value = '';
						duration.value = '';
						welcomeMessage.value = '';
						const properties = createAccountForm.querySelectorAll('.account-create__form-field-properties-item');
						const propertyField = createAccountForm.querySelector('.account-create__form-field--property');
						const propertySelect = propertyField.querySelector('.account-create__form-field-select');
						properties.forEach(property => {
							const listingId = property.dataset.listing;
							const nameEl = property.querySelector('.account-create__form-field-properties-item-property-name');
							const name = nameEl.textContent;
							propertySelect.options[propertySelect.options.length] = new Option(name, listingId);
							property.remove();
						});
						const clientGeneralUploadsField = createAccountForm.querySelector('.account-create__form-field--general-uploads');
						const clientGeneralUploads = clientGeneralUploadsField.querySelectorAll('.account-create__form-field-uploads-upload');
						const clientGeneralUploadsValidation = clientGeneralUploadsField.querySelectorAll('.account-create__form-field-validation');
						clientGeneralUploads.forEach(upload => {
							upload.remove();
						});
						clientGeneralUploadsField.classList.remove('invalid');
						clientGeneralUploadsValidation.textContent = '';
					}, 300);
				}
			}
		});
	}

	function updateAccountTable(result) {
		if (result.account_id) {
			var data = {
				action: 'ajax_get_account_table_row',
				account: result.account_id,
			}

			jQuery.ajax({
				url: php_data.custom_ajaxurl,
				async: true,
				cache: true,
				data: data,
				error: function(jqXHR, textStatus) {
					console.log("Status: " + jqXHR.status);
					console.log("textStatus: " + textStatus);
				},
				success: function(response) {
					if (response) {
						var tableBody = document.querySelector('.accounts-table__table-body');
						var noAccountsRow = tableBody.querySelector('.accounts-table__no-accounts');
						if (noAccountsRow) {
							noAccountsRow.remove();
						}
						tableBody.innerHTML = tableBody.innerHTML + response;
						var row = tableBody.querySelector('[data-account-id="' + result.account_id + '"]');
						setTimeout(function() {
							row.classList.remove('ajaxed');
						}, 2000);
					}
				}
			});
		}
	}

	function submitEditAccountForm(e) {
		e.preventDefault();
		var account = editAccountModal.dataset.account;
		var name = document.getElementById('e_' + nameFieldId);
		var nameField = name.parentNode;
		var nameFieldValidation = nameField.querySelector('.account-edit__form-field-validation');
		var access = document.getElementById('e_' + accessFieldId);
		var accessField = access.parentNode;
		var accessFieldValidation = accessField.querySelector('.account-edit__form-field-validation');
		var duration = document.getElementById('e_' + durationFieldId);
		var durationField = duration.parentNode;
		var durationFieldValidation = durationField.querySelector('.account-edit__form-field-validation');
		var welcomeMessage = document.getElementById('e_' + welcomeMessageFieldId);
		var welcomeMessageField = welcomeMessage.parentNode;
		var status = editAccountModal.querySelector('.account-edit__status');
		status.textContent = '';

		var submitButton = editAccountForm.querySelector('.account-edit__form-submit');
		submitButton.blur();

		var nameIsValid = accessIsValid = false;

		if (name.value.length < 1) {
			nameField.classList.add('invalid');
			nameFieldValidation.textContent = errorValues.empty[nameFieldId];
		} else {
			nameField.classList.remove('invalid');
			nameFieldValidation.textContent = '';
			nameIsValid = true;
		}
		if (access.value.length < 1) {
			accessField.classList.add('invalid');
			accessFieldValidation.textContent = errorValues.empty[accessFieldId];
		} else {
			accessField.classList.remove('invalid');
			accessFieldValidation.textContent = '';	
			accessIsValid = true;
		}

		if (!nameIsValid || !accessIsValid) return;

		const properties = editAccountForm.querySelectorAll('.account-edit__form-field-properties-item');
		let clientProperties = {};
		if (properties.length > 0) {
			let i = 0;
			properties.forEach(property => {
				const clientPropertyUploads = {};
				const propertyUploads = property.querySelectorAll('.account-edit__form-field-uploads-upload');
				let j = 0;
				propertyUploads.forEach(upload => {
					clientPropertyUploads[j] = {
						"file": upload.dataset.file,
						"label": upload.dataset.label,
					}
					j++;
				});
				clientProperties[i] = {
					"listing": property.dataset.listing,
					"uploads": clientPropertyUploads,
				};
				i++;
			});
		}

		const generalUploadsField = editAccountForm.querySelector('.account-edit__form-field--general-uploads');
		const generalUploads = generalUploadsField.querySelectorAll('.account-edit__form-field-uploads-upload');
		const clientGeneralUploads = {};
		if (generalUploads.length > 0) {
			let i = 0;
			generalUploads.forEach(upload => {
				clientGeneralUploads[i] = {"file": upload.dataset.file, "label": upload.dataset.label};
				i++;
			});
		}

		// Make form inactive
		editAccountModal.classList.add('inactive');

		let no_duration = false;
		if (duration.value.length < 1) {
			no_duration = true;
		}

		var data = {
			action: 'ajax_edit_account',
			account: account,
			name: name.value,
			access: access.value,
			no_duration: no_duration,
			duration: duration.value,
			message: welcomeMessage.value,
			properties: JSON.stringify(clientProperties),
			uploads: JSON.stringify(clientGeneralUploads),
		}

		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.error) {
					if (result.error === 'Code already in use') {
						accessField.classList.add('invalid');
						access.focus();
						accessFieldValidation.textContent = result.error;
					} else if (result.error === 'Account not found') {
						status.textContent = 'Account update failed, reload the page and try again';
					} else if (result.error === 'Duration must be at least 1 day or empty for no duration') {
						durationField.classList.add('invalid');
						duration.focus();
						durationFieldValidation.textContent = result.error;
					}
					editAccountModal.classList.remove('inactive');
				} else {
					editAccountModal.classList.remove('active');
					editAccountModal.classList.remove('inactive');
					updateAccountTableRow(result);
				}
			}
		});
	}

	function updateAccountTableRow(result) {
		if (result.account) {
			const row = document.querySelector('.accounts-table__table-body-row[data-account-id="' + result.account + '"]');
			var data = {
				action: 'ajax_get_account_table_row',
				account: result.account,
			}
			jQuery.ajax({
				url: php_data.custom_ajaxurl,
				async: true,
				cache: true,
				data: data,
				error: function(jqXHR, textStatus) {
					console.log("Status: " + jqXHR.status);
					console.log("textStatus: " + textStatus);
				},
				success: function(response) {
					if (response) {
						row.outerHTML = response;
						row.classList.add('ajaxed');
						setTimeout(() => {
							const replacedRow = document.querySelector('.accounts-table__table-body-row[data-account-id="' + result.account + '"]');
							replacedRow.classList.remove('ajaxed');
						}, 1000);
					}
				}
			});
		}
	}

	function accountInputBlur(e) {
		var field = e.target.parentNode;
		var fieldId = e.target.getAttribute('id');
		var validation = field.querySelector('.validation-message');
		if (e.target.value.length < 1) {
			field.classList.add('invalid');
			validation.textContent = errorValues.empty[fieldId];
		} else {
			field.classList.remove('invalid');
			validation.textContent = '';
		}
	}

	function accountResetValidation() {
		accountInputsForValidation.forEach(function(e, i) {
			var field = e.parentNode;
			var fieldId = field.id
			var validation = field.querySelector('.validation-message');
			field.classList.remove('invalid');
			validation.textContent = '';
		});
	}

	function accountPropertiesChange(e) {
		if (!e.target.classList.contains('property-select')) return;
		const select = e.target;
		createAccountModal.classList.add('inactive');

		let baseClass;
		if (select.classList.contains('account-edit__form-field-select')) {
			baseClass = 'account-edit';
		} else {
			baseClass = 'account-create';
		}

		const fieldParent = select.parentNode;
		const properties = fieldParent.querySelector('.field-properties');
		const fieldId = select.getAttribute('id');
		const fieldValue = select.value;
		const fieldName = select.options[select.options.selectedIndex].text;

		const item = document.createElement('div');
		item.classList.add(baseClass + '__form-field-properties-item');
		item.classList.add('field-properties-item');
		item.dataset.listing = fieldValue;

		const property = document.createElement('div');
		property.classList.add(baseClass + '__form-field-properties-item-property');
		item.appendChild(property);

		const propertyName = document.createElement('div');
		propertyName.classList.add(baseClass + '__form-field-properties-item-property-name');
		propertyName.textContent = fieldName;
		property.appendChild(propertyName);

		const propertyDelete = document.createElement('button');
		propertyDelete.classList.add(baseClass + '__form-field-properties-item-property-delete');
		propertyDelete.classList.add('property-delete');
		propertyDelete.type = 'button';
		property.appendChild(propertyDelete);

		const upload = createPropertyUploadHTML(fieldValue, baseClass);

		item.appendChild(upload);

		properties.appendChild(item);

		select.remove(select.options.selectedIndex);

		createAccountModal.classList.remove('inactive');
	}

	function createPropertyUploadHTML(id, baseClass) {
		const uploadId = 'property-' + id;
		
		const upload = document.createElement('div');
		upload.classList.add(baseClass + '__form-field-properties-item-uploads');

		const label = document.createElement('label');
		label.classList.add(baseClass + '__form-field-label');
		label.classList.add(baseClass + '__form-field-label--upload');
		label.setAttribute('for', uploadId);

		const text = document.createElement('span');
		text.classList.add(baseClass + '__form-field-label--upload-text');
		text.textContent = 'Click to Upload';
		label.appendChild(text);

		const icon = document.createElement('span');
		icon.classList.add(baseClass + '__form-field-label--upload-icon');
		icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.3 10.398"><path d="m8.19 4.208-1.74-1.74v4.281h-1.3V2.51L3.453 4.207l-.919-.919L5.821 0 9.11 3.289l-.92.919Zm3.11 3.54H9.999v1.35H1.3v-1.35H0v2.65h11.3v-2.65Z"/></svg>';
		label.appendChild(icon);

		const type = document.createElement('span');
		type.classList.add(baseClass + '__form-field-label--upload-type');
		type.textContent = 'PDF';
		label.appendChild(type);

		const size = document.createElement('span');
		size.classList.add(baseClass + '__form-field-label--upload-size');
		size.textContent = '> 5MB';
		label.appendChild(size);

		upload.appendChild(label);

		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.id = uploadId;
		input.setAttribute('name', uploadId);
		input.classList.add(baseClass + '__form-field-file');
		input.classList.add('form-field-file');

		upload.appendChild(input);

		const uploads = document.createElement('div');
		uploads.classList.add(baseClass + '__form-field-uploads');

		upload.appendChild(uploads);

		const validation = document.createElement('div');
		validation.classList.add(baseClass + '__form-field-validation');

		upload.appendChild(validation);

		return upload;
	}

	function accountPropertyDelete(e) {
		if (!e.target.classList.contains('property-delete')) return;
		let baseClass;
		if (e.target.classList.contains('account-create__form-field-properties-item-property-delete')) {
			baseClass = '.account-create';
		} else {
			baseClass = '.account-edit';
		}
		const button = e.target;
		const item = button.closest(baseClass + '__form-field-properties-item');
		const name = item.querySelector(baseClass + '__form-field-properties-item-property-name');
		const value = item.dataset.listing;
		const text = name.textContent;
		const field = button.closest(baseClass + '__form-field--property');
		const select = field.querySelector(baseClass + '__form-field-select');
		select.options[select.options.length] = new Option(text, value);
		// Check for property uploads
		const uploads = item.querySelectorAll(baseClass + '__form-field-uploads-upload');
		uploads.forEach(upload => {
			const deleteButton = upload.querySelector(baseClass + '__form-field-uploads-upload-delete');
			deleteButton.click();
		});
		setTimeout(() => {
			item.remove();
		}, 100);
	}

	function createUploadHTML(id, name, bc) {
		const baseClass = bc.substring(1);
		const upload = document.createElement('div');
		upload.classList.add(baseClass + '__form-field-uploads-upload');
		upload.id = 'file-' + id;
		upload.dataset.file = id;
		upload.dataset.label = name;
		const uploadName = document.createElement('div');
		uploadName.classList.add(baseClass + '__form-field-uploads-upload-name');
		uploadName.textContent = name;
		upload.appendChild(uploadName);
		const uploadEdit = document.createElement('button');
		uploadEdit.classList.add(baseClass + '__form-field-uploads-upload-edit');
		uploadEdit.classList.add('upload-edit');
		uploadEdit.type = 'button';
		upload.appendChild(uploadEdit);
		const uploadDelete = document.createElement('button');
		uploadDelete.classList.add(baseClass + '__form-field-uploads-upload-delete');
		uploadDelete.classList.add('upload-delete');
		uploadDelete.type = 'button';
		upload.appendChild(uploadDelete);
		return upload;
	}

	function accountUploadChange(e) {
		if (!e.target.classList.contains('form-field-file')) return;
		let baseClass;
		let modal;
		if (e.target.classList.contains('account-edit__form-field-file')) {
			baseClass = '.account-edit';
			modal = editAccountModal;
		} else {
			baseClass = '.account-create';
			modal = createAccountModal;
		}
		// https://www.pixelnet.in/blog/tutorials/how-to-upload-files-in-wordpress-frontend-using-ajax/
		modal.classList.add('inactive');
		const field = e.target;
		const inputId = field.id;
		const fieldParent = field.parentNode;
		const fieldUploads = fieldParent.querySelector(baseClass + '__form-field-uploads');
		const fieldValidation = fieldParent.querySelector(baseClass + '__form-field-validation');
		const form = fieldParent.closest(baseClass + '__form');
		const fileName = modal.querySelector(baseClass + '__file-name');

		// Get the form data and store in a variable
		var formData = new FormData(form);
		formData.append('input', inputId);
		formData.append('action', 'ajax_file_upload');

		// Prepare and send the call
		jQuery.ajax({
			type: "POST",
			data: formData,
			url: php_data.custom_ajaxurl,
			cache: false,
			processData: false,
			contentType: false,
			enctype: 'multipart/form-data',
			success: function(data, textStatus, jqXHR) {
				modal.classList.remove('inactive');
				const response = JSON.parse(data);
				if (response.error) {
					fieldValidation.textContent = response.error;
					fieldParent.classList.add('invalid');
					field.value = '';
				} else {
					fieldValidation.textContent = '';
					fieldParent.classList.remove('invalid');
					fileName.classList.add('active');
					const filenameInput = fileName.querySelector(baseClass + '__file-name-input');
					filenameInput.setAttribute('placeholder', response.file_name);
					filenameInput.dataset.file = 'file-' + response.file_id;
					setTimeout(() => {
						filenameInput.focus();
					}, 100);

					const upload = createUploadHTML(response.file_id, response.file_name, baseClass);

					fieldUploads.appendChild(upload);

					field.value = '';
				}
			},
			error: function(jqXHR, textStatus, errorThrown) {
				console.log(jqXHR, textStatus);
			}
		});
	}

	function uploadEditClick(e) {
		const button = e.target;
		if (!button.classList.contains('upload-edit')) return;
		let baseClass;
		const upload = button.parentNode;

		const file = 'file-' + upload.dataset.file;
		const label = upload.dataset.label;
		let fileName;
		let fileNameInput;
		if (button.classList.contains('account-edit__form-field-uploads-upload-edit')) {
			fileName = editAccountModal.querySelector('.account-edit__file-name');
			fileNameInput = fileName.querySelector('.account-edit__file-name-input');
		} else {
			fileName = createAccountModal.querySelector('.account-create__file-name');
			fileNameInput = fileName.querySelector('.account-create__file-name-input');
		}

		fileNameInput.dataset.file = file;
		fileNameInput.setAttribute('placeholder', label);

		fileName.classList.add('active');

		setTimeout(() => {
			fileNameInput.focus();
			fileNameInput.value = label;
		}, 100);
	}

	function uploadDeleteClick(e) {
		const button = e.target;
		if (!button.classList.contains('upload-delete')) return;
		const isEdit = button.classList.contains('account-edit__form-field-uploads-upload-delete') ? true : false;
		let account = false;
		let propertyIndex = false;
		let uploadIndex = false;
		if (isEdit) {
			const modal = button.closest('aside');
			account = modal.dataset.account;
			const propertyItem = button.closest('.field-properties-item');
			if (propertyItem) {
				propertyIndex = propertyItem.dataset.index;
			}
			uploadIndex = button.closest('.account-edit__form-field-uploads-upload').dataset.index;
		}

		const upload = button.parentNode;
		const file = upload.dataset.file;
		var data = {
			action: 'ajax_file_delete',
			file: file,
			isEdit: isEdit,
			account: account,
			propertyIndex: propertyIndex,
			uploadIndex: uploadIndex,
		}
		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.success) {
					upload.remove();
				} else if (result.error) {
					console.log(result.error);
				}
			}
		});
	}

	function uploadSetFilename(e) {
		const button = e.target;
		if (!button.classList.contains('account-create__file-name-save') && !button.classList.contains('account-edit__file-name-save')) return;
		let baseClass = '.account-create__';
		if (button.classList.contains('account-edit__file-name-save')) {
			baseClass = '.account-edit__';
		}
		const parent = button.parentNode;
		const grandparent = parent.parentNode;
		const input = parent.querySelector(baseClass + 'file-name-input');
		if (!input.value) {
			return;
		}
		const fileId = input.dataset.file;
		const file = document.querySelector('#' + fileId);
		const fileName = file.querySelector(baseClass + 'form-field-uploads-upload-name');
		fileName.textContent = input.value;
		file.dataset.label = input.value;
		input.value = '';
		input.dataset.file = '';
		input.setAttribute('placeholder', '');
		grandparent.classList.remove('active');
	}

	function copyAccountAccessUrl(e) {
		const el = e.target;
		if (!el.classList.contains('copy-url')) return;
		const url = el.dataset.url;
		const copied = copyToClipboard(url);
		if (copied) {
			const width = el.offsetWidth;
			el.style.width = width + 'px';
			const label = el.textContent;
			el.textContent = 'Copied!';
			setTimeout(function() {
				el.textContent = label;
				el.removeAttribute('style');
			}, 3000);
		}
	}

	function incrementAccountDuration(e) {
		var el = e.target;
		if (!el.classList.contains('accounts-table__table-body-duration-increment') && !el.classList.contains('accounts-table__table-body-details-duration-increment')) return;
		el.disabled = true;
		var row = el.closest('.accounts-table__table-body-row');
		var dur_mobile = row.querySelector('.accounts-table__table-body-details-duration-value-text');
		var dur_desktop = row.querySelector('.accounts-table__table-body-duration-value-text');
		var time_remaining_mobile = row.querySelector('.accounts-table__table-body-details-remaining-value');
		var time_remaining_desktop = row.querySelector('.time-remaining');
		var account_id = row.getAttribute('data-account-id');
		var data = {
			action: 'ajax_update_account_duration',
			account: account_id,
		}

		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.duration) {
					dur_mobile.classList.add('inactive');
					dur_desktop.classList.add('inactive');
				}
				if (result.time_remaining) {
					time_remaining_mobile.classList.add('inactive');
					time_remaining_desktop.classList.add('inactive');
				}
				setTimeout(function() {
					if (result.duration) {
						dur_mobile.innerHTML = result.duration;
						dur_desktop.innerHTML = result.duration;
					}
					if (result.time_remaining) {
						time_remaining_mobile.textContent = result.time_remaining;
						time_remaining_desktop.textContent = result.time_remaining;
					}
					dur_mobile.classList.remove('inactive');
					dur_desktop.classList.remove('inactive');
					time_remaining_mobile.classList.remove('inactive');
					time_remaining_desktop.classList.remove('inactive');
				}, 250);
				setTimeout(function() {
					e.target.disabled = false;
				}, 350);
			}
		});
	}

	function confirmDeleteAccount(e) {
		const el = e.target;
		if (!el.classList.contains('account-edit__form-delete')) return;
		const confirmDelete = editAccountModal.querySelector('.account-edit__delete-confirm');
		confirmDelete.classList.add('active');
	}

	function cancelDeleteAccount(e) {
		const el = e.target;
		if (!el.classList.contains('account-edit__delete-confirm-cancel')) return;
		const confirmDelete = editAccountModal.querySelector('.account-edit__delete-confirm');
		confirmDelete.classList.remove('active');
	}

	function deleteAccount(e) {
		const el = e.target;
		if (!el.classList.contains('account-edit__delete-confirm-button')) return;
		// Make form inactive
		editAccountModal.classList.add('inactive');
		const modal = el.closest('aside');
		const account_id = modal.dataset.account;
		const row = document.querySelector('.accounts-table__table-body-row[data-account-id="' + account_id + '"]');
		const uploadDeleteButtons = modal.querySelectorAll('.upload-delete');
		uploadDeleteButtons.forEach(button => {
			button.click();
		});
		var data = {
			action: 'ajax_delete_account',
			account: account_id,
		}
		jQuery.ajax({
			url: php_data.custom_ajaxurl,
			async: true,
			cache: true,
			data: data,
			error: function(jqXHR, textStatus) {
				console.log("Status: " + jqXHR.status);
				console.log("textStatus: " + textStatus);
			},
			success: function(response) {
				var result = JSON.parse(response);
				if (result.deleted) {

					const confirmDelete = editAccountModal.querySelector('.account-edit__delete-confirm');
					confirmDelete.classList.remove('active');

					row.classList.add('deleted');

					editAccountModal.classList.remove('active');
					editAccountModal.classList.remove('inactive');

					gsap.to(row, {duration: .5, height: 0, padding: 0, autoAlpha: 0, ease: "power4.easeInOut", onComplete: () => {
						row.remove();
					}});
				}
			}
		});
	}

	function toggleAccountTableRowDetails(e) {
		const el = e.target;
		if (!el.classList.contains('accounts-table__table-body-actions-expand')) return;
		const id = el.getAttribute('aria-controls');
		const content = document.querySelector('#' + id);
		const isExpanded = el.getAttribute('aria-expanded');
		if (isExpanded === 'false') {
			gsap.set(content, {autoAlpha: 1, height: 'auto'});
			gsap.from(content, {duration: .6, autoAlpha: 1, height: 0, ease: "power4.easeInOut"});
			content.setAttribute('aria-hidden', 'false');
			content.classList.add('open');
			el.setAttribute('aria-expanded', 'true');
			el.classList.add('open');
		} else {
			gsap.to(content, {duration: .4, autoAlpha: 1, height: 0, ease: "power4.easeInOut"});
			setTimeout(() => {
				content.setAttribute('aria-hidden', 'true');
				content.classList.remove('open');
				el.setAttribute('aria-expanded', 'false');
				el.classList.remove('open');
			}, 10);
		}
	}

	// Event handler for opening create account modal
	document.addEventListener('click', openCreateAccountModal, false);

	// Event handler for opening edit account modal
	document.addEventListener('click', openEditAccountModal, false);

	// Event handler for closing modals
	document.addEventListener('click', closeAccountModal, false);

	// Escape key event close modal listener
	window.addEventListener('keydown', closeOpenAccountModal, false);

	// Click outside event close modal listener
	document.addEventListener('click', closeOpenAccountModal, false);

	// Event handler for create account form submit
	createAccountForm.addEventListener('submit', submitCreateAccountForm, false);

	// Event handler for edit account form submit
	editAccountForm.addEventListener('submit', submitEditAccountForm, false);

	// Event handler for form input blurs
	accountInputsForValidation.forEach((e, i) => {
		e.addEventListener('blur', accountInputBlur, false);
	});

	// Property field handler
	document.addEventListener('change', accountPropertiesChange, false);

	// Property delete handler
	document.addEventListener('click', accountPropertyDelete, false);

	// General Uploads field handler
	document.addEventListener('change', accountUploadChange, false);

	// Upload Edit label handler
	document.addEventListener('click', uploadEditClick, false);

	// Upload delete handler
	document.addEventListener('click', uploadDeleteClick, false);

	// Set file name button handler
	document.addEventListener('click', uploadSetFilename, false);

	// Event handler for copy url buttons
	document.addEventListener('click', copyAccountAccessUrl, false);

	// Event handler for increment duration buttons
	document.addEventListener('click', incrementAccountDuration, false);

	// Event handler for confirm delete button
	document.addEventListener('click', confirmDeleteAccount, false);

	// Event handler for delete account button
	document.addEventListener('click', deleteAccount, false);

	// Event handler for cancel delete account button
	document.addEventListener('click', cancelDeleteAccount, false);

	// Event handler for account table row details toggle
	document.addEventListener('click', toggleAccountTableRowDetails, false);
}
