window.ajaxurl = window.ajaxurl || '/wp-admin/admin-ajax.php' // Fallback to default if it's not set

require('./src/accounts.js')
require('./src/carousels.js')
require('./src/countdown')
require('./src/holding-page-form')
require('./src/form')
require('./src/scrollEvents')
require('./src/services-grid')
require('./src/mobileMenu')
require('./src/buttons')
require('./src/listing-popups')
require('./src/property-filter')
// require('./src/page-transition');

document.addEventListener('DOMContentLoaded', () => {
// Copy Link

	const copyLinkBtn = document.querySelectorAll('.copy-link')

	if (copyLinkBtn.length > 0) {
		copyLinkBtn.forEach(button => {
			button.addEventListener('click', () => {
				// Create a temporary input element to hold the URL
				const tempInput = document.createElement('input')
				const url = window.location.href
				tempInput.value = url
				// Append the input element to the document body
				document.body.appendChild(tempInput)
				// Select the input element's value
				tempInput.select()
				// Copy the selected value to the clipboard
				document.execCommand('copy')
				// Remove the temporary input element from the document
				document.body.removeChild(tempInput)
			})
		})
	}

// ------------------------------------------------------------------
// Contact page text box

	const contactPageTextBox = document.querySelector('section.contact .pinned-col .text-box')

	if (contactPageTextBox) {
		const copyContainer = contactPageTextBox.querySelector('.copy-container')

		function setContactPageTextBoxHeight () {
			contactPageTextBox.style.maxHeight = `${copyContainer.offsetHeight + 240}px`
		}

		if (window.matchMedia('(min-width: 1260px)').matches) {
			setContactPageTextBoxHeight()
		}

		window.addEventListener('resize', setContactPageTextBoxHeight)
	}

	// ------------------------------------------------------------------
	// Move first section down if logged in to accommodate admin banner

	if (document.querySelector('body').classList.contains('logged-in')) {

		function configureFirstSectionMargin () {
			const firstSection = document.querySelector('section:first-child:not(.hero.full-bleed):not(.hero.short):not(.contact)')
			const mainHeader = document.querySelector('header#site-header')
			const adminBanner = document.querySelector('header.admin-controls')

			if (firstSection && adminBanner) {
				const newMarginTop = mainHeader.offsetHeight + adminBanner.offsetHeight
				firstSection.style.marginTop = newMarginTop + 'px'
			}
		}

		configureFirstSectionMargin()
		window.addEventListener('resize', configureFirstSectionMargin)
	}

})
