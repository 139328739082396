import Swiper from 'swiper/bundle';

document.addEventListener("DOMContentLoaded", () => {

    if (document.querySelector(".testimonials-swiper")) {

        const swiper = new Swiper('.testimonials-swiper', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            slidesPerView: 'auto',
            speed: 500,
            // spaceBetween: 24,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 10000,
                disableOnInteraction: true,
            },
        });

    }


    if (document.querySelector(".listing-hero-swiper")) {
        const slides = document.querySelectorAll('.listing-hero-swiper .swiper-slide');

        setTimeout(() => {
            const swiper = new Swiper('.listing-hero-swiper', {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                loop: slides.length > 3,
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 40,
                speed: 500,
                grabCursor: true,
                breakpoints: {
                    720: {
                        spaceBetween: 120,
                    }
                }
            });
        }, 100)

    }


    if (document.querySelector(".listing-floor-plan-swiper")) {

        const floorPlanSwiper = new Swiper('.listing-floor-plan-swiper', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            grabCursor: true,
            breakpoints: {
                720: {
                    spaceBetween: 120,
                }
            },
            zoom: true,
        });

        const zoomInButton = document.querySelector('.listing-floor-plan-swiper [data-zoom="in"]');
        const zoomOutButton = document.querySelector('.listing-floor-plan-swiper [data-zoom="out"]');

        zoomInButton.addEventListener("click", () => {
            floorPlanSwiper.zoom.in();
        })

        zoomOutButton.addEventListener("click", () => {
            floorPlanSwiper.zoom.out();
        })

    }

    if (document.querySelector(".listing-gallery-swiper")) {

        const gallerySwiper = new Swiper('.listing-gallery-swiper', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            loop: true,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            grabCursor: true,
            breakpoints: {
                720: {
                    spaceBetween: 120,
                }
            },
            zoom: {maxRatio: 2.5},
        });

        const zoomInButton = document.querySelector('.listing-gallery-swiper [data-zoom="in"]');
        const zoomOutButton = document.querySelector('.listing-gallery-swiper [data-zoom="out"]');
        const zoomStep = 0.5;

        zoomInButton.addEventListener("click", () => {
            gallerySwiper.zoom.in();
        })

        zoomOutButton.addEventListener("click", () => {
            gallerySwiper.zoom.out();
        })
    }
})