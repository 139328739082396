document.addEventListener('DOMContentLoaded', function () {
	const emailInput = document.querySelector("#holding-page .form-container input[type=email]");
	const acceptanceInput = document.querySelector("#holding-page .form-container label.terms-input");

	if (emailInput) {
		emailInput.addEventListener('input', () => {
			if (emailInput.value) {
				acceptanceInput.classList.add('visible');
			} else {
				acceptanceInput.classList.remove('visible');
			}
		})
	}


	const form = document.querySelector("#holding-page #mc-embedded-subscribe-form");

	const checkbox = document.querySelector('#holding-page #terms');
	if (checkbox) {
		checkbox.addEventListener('change', () => {
			if (checkbox.checked && emailInput.checkValidity()) {
				form.classList.remove("disabled");
			} else {
				form.classList.add('disabled');
			}
		})

		emailInput.addEventListener('change', () => {
			if (checkbox.checked && emailInput.checkValidity()) {
				form.classList.remove("disabled");
			} else {
				form.classList.add('disabled');
				form.classList.add('disabled');
			}
		})
	}


	if (form) {
		function logReset(event) {
			formContainer.style.display = 'none';
			formSuccessMessage.style.display = 'block';
		}

		const formSuccessMessage = document.getElementById("form-success-message");
		form.addEventListener("reset", logReset);

		const formContainer = document.querySelector("#holding-page .form-container")
		formSuccessMessage.style.height = `${formContainer.offsetHeight}px`;
	}
})