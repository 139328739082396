document.addEventListener("DOMContentLoaded", () => {
    let buttons = document.querySelectorAll(".animated-button");

    const activateButton = (event) => {
        const button = event.target.closest(".animated-button");
        buttons.forEach(btn => btn.classList.remove("active"));
        button.classList.add("active");
    };

    const removeActiveClass = (event) => {
        const clickedButton = event.target.closest(".animated-button");
        if (!clickedButton) {
            // Click occurred outside of any button, remove 'active' class from all
            buttons.forEach(button => {
                button.classList.remove("active");
            });
        } else {
            // Click occurred on a button, remove 'active' class from all except the clicked one
            buttons.forEach(button => {
                if (button !== clickedButton) {
                    button.classList.remove("active");
                }
            });
        }
    };

    const manageButtonEventListeners = () => {
        buttons = document.querySelectorAll(".animated-button.contact");
        document.addEventListener("click", removeActiveClass);
        buttons.forEach(button => {
            button.addEventListener("click", activateButton);
        });

    };

    manageButtonEventListeners();

    window.refreshButtonEventListeners = manageButtonEventListeners;

});
